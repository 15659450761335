<template>
       <!-- help-crm -->
    <section class="help-crm">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="help-area">
                        <h1>DealersGear CRM Help</h1>
                        <h4>Add a Customer (via Desktop):</h4>
                        <p>To add a customer click on the green “Add Leadsheet +” button in top right of CRM.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/e8bfd27e8854143691387827633f97f6.jpg" alt="">
                        <p>Fill out the information to add a Customer. When adding a Customer for the first time it will automatically create a Leadsheet. You must have one form of contact information (Email Address or Phone Number).</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/1034a62def926e74884fe1eaebca174d.jpg" alt="">
                        <p>Once you click “+ Add” Button it will open Leadsheet.</p>
                        <p>Do make changes or to add addition contact information (Address, etc), click on Edit pencil icon.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/720df7a7052d82f91556b8a6953bcb85.jpg" alt="">
                        <p>Click “+ Update” button to Save</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/b313ce5e11349f28dfe9a2e404c7baf2.jpg" alt="">

                        <h4>Add a Customer (via DealersGear CRM Mobile App):</h4>
                        <p>Click on the Green “+” Icon on bottom of App.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/e8b022cbf88a3184fa65bdadf582cb36.jpg" alt="">
                        <p>You can Add a Customer via Mobile App via a Driver’s License Scan (Photo), or Manually.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/861fcf9dee107efffcc979b2607fc0f6.jpg" alt="">
                        <p>To Scan License, position license in front of camera and take photo. (Click Allow Camera, Photo Access to Allow).</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/9232d05e6ae27cf77bcefd1a798a8e48.jpg" alt="">
                        <p>To Add a Customer Manually:</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/1beb39a19d85e32d37ca403b45ed108b.jpg" alt="">

                        <h4>Search for a Customer (Desktop):</h4>
                        <p>Click on the magnifying glass icon and enter Name, Phone Number or Email Address of Customer you are searching.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/b86bdc1898ed4dd4722280bd3f7469b9.jpg" alt="">
                        <p>Matched Customer Record will then be listed.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/c921761ad82aa2046ad5f66a8abe6419.jpg" alt="">
                        <p>Clicking on Customer Name will open the Customer Detail Screen which will show all Leadsheets, Appointments, Open Tasks, and Communication Timeline.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/128245742eefb1af7e646fbac8204235.jpg" alt="">
                        <h4>Search for a Customer (via DealersGear CRM Mobile App):</h4>
                        <p>Click on the magnifying glass icon and enter Name, Phone Number or Email Address of Customer you are searching.</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/ff8e0311630723fb7773c4eca9685fae.jpg" alt="">
                        <p>Search Results Page</p>
                        <img class="img-fluid" src="https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/afa5f9df52f2d8f6a43a3fee3559851e.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- help-crm -->  
</template> 
<script>

export default {
    name: 'help'
    
}
</script>